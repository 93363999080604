import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import bg1 from "../../images/bg_1.jpg";
import bg2 from "../../images/bg_2.jpg";
import { Link } from "react-router-dom";
import "./banner.css"

const Banner = () => {
  return (
    <section id="home-section" className="hero">
      <h3 className="vr">Welcome to Eventease360</h3>

      <OwlCarousel
        className="home-slider js-fullheight owl-carousel  owl-loaded owl-drag"
        loop={true}
        margin={20}
        nav={false}
        dots={false}
        autoplay={true}
        autoplayTimeout={2000}
        autoHeight={true}
        items={1}
        style={{ height: "100vh" }}
        autoplayHoverPause={true}
        animateOut="fadeOut"
        animateIn="fadeIn"
        // navText={[
        //   '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        //   '<i class="fa fa-angle-right" aria-hidden="true"></i>',
        // ]}
        responsive={{
          0: {
            items: 1,
          },
          576: {
            items: 1,
          },
          768: {
            items: 1,
          },
          992: {
            items: 1,
          },
          1200: {
            items: 1,
          },
        }}
      >
        <div className="slider-item js-fullheight item zoom-in">
          <div className="overlay" />
          <div className="container-fluid p-0">
            <div
              className="row d-md-flex no-gutters slider-text js-fullheight align-items-center justify-content-end"
              data-scrollax-parent="true"
            >
              <div
                className="one-third order-md-last img js-fullheight"
                style={{ backgroundImage: "url(/assets/images/1.png)" }}
              >
                <div className="overlay" />
              </div>
              <div
                className="one-forth d-flex js-fullheight align-items-center "
                data-scrollax=" properties: { translateY: '70%' }"
              >
                <div className="text">
                  <span className="subheading">
                    Welcome to the Event Ease 360 family!
                  </span>
                  <h1 className="mb-4 mt-3">
                    The best software out there{" "}
                    <span>for running your market!</span>
                  </h1>
                  <p>
                    Streamlining the boring parts, so you focus on what you
                    love, your market.
                  </p>
                  <p>
                    <Link
                      to={"/free-trial"}
                      className="btn btn-primary px-5 py-3 mt-3 smooth-scroll"
                    >
                      Free Trial
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-item js-fullheight item zoom-in">
          <div className="overlay" />
          <div className="container-fluid p-0">
            <div
              className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end"
              data-scrollax-parent="true"
            >
              <div
                className="one-third order-md-last img js-fullheight"
                style={{ backgroundImage: "url(/assets/images/2.png)" }}
              >
                <div className="overlay" />
              </div>
              <div
                className="one-forth d-flex js-fullheight align-items-center "
                data-scrollax=" properties: { translateY: '70%' }"
              >
                <div className="text">
                  <span className="subheading">
                    Welcome to the Eventease360
                  </span>
                  <h1 className="mb-4 mt-3">
                    <span>We believe farmers' markets</span> help build
                    stronger, <span>healthier communities</span>
                  </h1>
                  <p>Farmers Market Management Made Easy.</p>
                  <p>
                    <Link
                      to={"/free-trial"}
                      className="btn btn-primary px-5 py-3 mt-3 smooth-scroll"
                    >
                      Buy Now
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-item js-fullheight item zoom-in">
          <div className="overlay" />
          <div className="container-fluid p-0">
            <div
              className="row d-md-flex no-gutters slider-text js-fullheight align-items-center justify-content-end"
              data-scrollax-parent="true"
            >
              <div
                className="one-third order-md-last img js-fullheight"
                style={{ backgroundImage: "url(/assets/images/3.png)" }}
              >
                <div className="overlay" />
              </div>
              <div
                className="one-forth d-flex js-fullheight align-items-center "
                data-scrollax=" properties: { translateY: '70%' }"
              >
                <div className="text">
                  <span className="subheading">
                    Welcome to the Event Ease 360 family!
                  </span>
                  <h1 className="mb-4 mt-3">
                    The best software out there{" "}
                    <span>for running your market!</span>
                  </h1>
                  <p>
                    Streamlining the boring parts, so you focus on what you
                    love, your market
                  </p>
                  <p>
                    <i>From Concept to Execution, We’ve Got You Covered !</i> Our
                    platform is designed to save your time and reduce stress,
                    ensuring every detail is handled with precision. Join
                    countless market organizers who trust Event Ease 360 for
                    their event planning needs.
                  </p>
                  <p>
                    <Link
                      to={"/free-trial"}
                      className="btn btn-primary px-5 py-3 mt-3 smooth-scroll"
                    >
                      Free Trial
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-item js-fullheight item zoom-in">
          <div className="overlay" />
          <div className="container-fluid p-0">
            <div
              className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end"
              data-scrollax-parent="true"
            >
              <div
                className="one-third order-md-last img js-fullheight"
                style={{ backgroundImage: "url(/assets/images/4.png)" }}
              >
                <div className="overlay" />
              </div>
              <div
                className="one-forth d-flex js-fullheight align-items-center "
                data-scrollax=" properties: { translateY: '70%' }"
              >
                <div className="text">
                  <span className="subheading">
                    Welcome to the Eventease360
                  </span>
                  <h1 className="mb-4 mt-3">
                    <span>We believe farmers' markets</span> help build
                    stronger, <span>healthier communities</span>
                  </h1>
                  <p>Farmers Market Management Made Easy</p>
                  <p>
                    Unlock Seamless Event Planning – From Concept to Execution,
                    We’ve Got You Covered! Our intuitive platform handles
                    everything from scheduling to vendor management, making your
                    event run effortlessly. Focus on growth and engagement,
                    while we take care of the logistics behind the scenes.
                  </p>
                  <p>
                    <Link
                      to={"/free-trial"}
                      className="btn btn-primary px-5 py-3 mt-3 smooth-scroll"
                    >
                      Buy Now
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slider-item js-fullheight item zoom-in">
          <div className="overlay" />
          <div className="container-fluid p-0">
            <div
              className="row d-md-flex no-gutters slider-text js-fullheight align-items-center justify-content-end"
              data-scrollax-parent="true"
            >
              <div
                className="one-third order-md-last img js-fullheight"
                style={{ backgroundImage: "url(/assets/images/5.png)" }}
              >
                <div className="overlay" />
              </div>
              <div
                className="one-forth d-flex js-fullheight align-items-center "
                data-scrollax=" properties: { translateY: '70%' }"
              >
                <div className="text">
                  <span className="subheading">
                    Welcome to the Event Ease 360 family!
                  </span>
                  <h1 className="mb-4 mt-3">
                    The best software out there{" "}
                    <span>for running your market!</span>
                  </h1>
                  <p>
                    Streamlining the boring parts, so you focus on what you
                    love, your market
                  </p>
                  <p>
                    <i>From Vision to Reality – Event Planning Made Effortless ! </i>
                     With Event Ease 360, you’ll have all the tools you need to
                    organize successful markets and events. Let us handle the
                    details while you focus on creating memorable experiences
                    for your vendors and visitors.
                  </p>
                  <p>
                    <Link
                      to={"/free-trial"}
                      className="btn btn-primary px-5 py-3 mt-3 smooth-scroll"
                    >
                      Free Trial
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-item js-fullheight item zoom-in">
          <div className="overlay" />
          <div className="container-fluid p-0">
            <div
              className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end"
              data-scrollax-parent="true"
            >
              <div
                className="one-third order-md-last img js-fullheight"
                style={{ backgroundImage: "url(/assets/images/6.png)" }}
              >
                <div className="overlay" />
              </div>
              <div
                className="one-forth d-flex js-fullheight align-items-center "
                data-scrollax=" properties: { translateY: '70%' }"
              >
                <div className="text">
                  <span className="subheading">
                    Welcome to the Eventease360
                  </span>
                  <h1 className="mb-4 mt-3">
                    <span>We believe farmers' markets</span> help build
                    stronger, <span>healthier communities</span>
                  </h1>
                  <p>Farmers Market Management Made Easy</p>
                  <p>
                   <i>Simplify Your Event Planning Journey with Event Ease 360 ! </i> 
                    From managing vendors to tracking attendance, our software
                    ensures nothing is overlooked. Stay organized and
                    stress-free, so you can focus on the big picture and enjoy
                    the process.
                  </p>
                  <p>
                    <Link
                      to={"/free-trial"}
                      className="btn btn-primary px-5 py-3 mt-3 smooth-scroll"
                    >
                      Buy Now
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default Banner;
