import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import Loader from "./Component/loader/Loader";
import { Context } from "./context/Context";
import Home from "./Component/landingPage/Home";
import SubAdminSignUp from "./Component/subAdminSignup/SubAdminSignUp";
import SnackbarComponent from "./Component/snackbarComponent/SnackbarComponent";

function App() {
  const { isLoading } = useContext(Context);
  return (
    <>
      <SnackbarComponent />
      <Router>
        <Header /> 
        <Loader open={isLoading} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/free-trial" element={<SubAdminSignUp />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
export default App;
