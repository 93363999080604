import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const LoginEndPoint = process.env.REACT_APP_LOGIN_PAGE_URL;

  const { pathname } = useLocation();
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light site-navbar-target menu_fixed"
      id="ftco-navbar"
    >
      <div className="container">
        <a href="/" className="navbar-brand">
          <img
            src="/assets/images/logo.svg"
            className="img-fluid"
            alt="Eventease360"
            width={200}
          />
        </a>
        <button
          className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="oi oi-menu" /> Menu
        </button>
        {pathname === "/" ? (
          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav nav ml-auto">
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <span>Home</span>
                </a>
              </li>

              <li className="nav-item">
                <a href="#projects-section" className="nav-link">
                  <span>Demo Videos</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="#testimony-section" className="nav-link">
                  <span>Testimonials</span>
                </a>
              </li>
              <li className="nav-item">
                <Link to="/free-trial" className="nav_link">
                  <span>Free Trial</span>
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={() =>
                window.open(LoginEndPoint)
                }
              >
                 <a className="nav-link">
                  <span>Login</span>
                  </a>
              </li>
            </ul>
          </div>
        ) : (
          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav nav ml-auto">
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <span>Home</span>
                </a>
              </li>

              <li className="nav-item">
                <Link to="/" className="nav_link">
                  <span>Demo Videos</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav_link">
                  <span>Testimonials</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/free-trial" className="nav_link">
                  <span>Free Trial</span>
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={() =>
                  window.open(LoginEndPoint)
                }
              >
                <a className="nav-link">
                  <span>Login</span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
