import React from "react";

const VisionBanner = () => {
  return (
    <>
      <section className="ftco-counter our_vision">
        <div className="row d-flex">
          <div className="col-xl-6 d-flex">
            <div
              className="img d-flex align-self-stretch align-items-center vision_img"
              // style={{
              //   backgroundImage:
              //     "url(/assets/images/vision.jpg); min-height: 400px",
              // }}
            ></div>
          </div>
          <div className="col-xl-6">
            <div className="py-xl-4">
              <div className="row justify-content-start pb-3">
                <div className="col-md-12">
                  <img
                    src="/assets/images/vision-icon.png"
                    className="vision_icon"
                  />
                  <div className="h-100 p-3 p-xl-5 ml-0 ml-xl-5 mb-4 mb-xl-0">
                    <h2 className="text-white font-weight-bold mb-3 pt-5 pt-xl-0 mt-4 mt-xl-0">
                      Our Vision
                    </h2>
                    <h5 className="mb-3 text-white">
                      At Event Ease 360, our journey began out of a simple yet
                      profound necessity: to simplify the intricate world of
                      event and market management. Fueled by our own experiences
                      and challenges, we embarked on a mission to craft a
                      solution that not only addresses these complexities but
                      transcends them.
                    </h5>
                    <h5 className="text-white mb-0">
                      Our vision is rooted in the belief that every event, every
                      collaboration, every market endeavor should flow
                      effortlessly, allowing you to focus on what truly
                      matters—creating memorable experiences and driving
                      success. With each feature meticulously added to our
                      software, we strive to redefine efficiency and ease,
                      ensuring that your journey towards seamless management is
                      as personable, friendly, and empowering as possible. Join
                      us in revolutionizing the way you navigate events and
                      markets—where necessity meets innovation, and where
                      success becomes the standard.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisionBanner;
