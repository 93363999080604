import React, { useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { useFormik } from "formik";
import * as Yup from "yup";

const SubAdminSignUp = () => {
  const { singupApi, checkEmailApi } = useApiAxios();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      organization: "",
      about: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .min(2, "Name should be at least 2 characters")
        .matches(/^[^\s].*$/, "Name should not start with a space"),


      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^\+?\d{1,10}$/, "Invalid phone number")
        .max(10, "Phone number should not exceed 10 digits")
        .required("Phone number is required"),
        about: Yup.string()
        .test(
          "is-valid-about",
          "About should not contain only spaces",
          (value) => !/^[\s\n]*$/.test(value)
        )
        .matches(
          /^[a-zA-Z0-9\s\S]*$/,
          "About should only contain alphabets, numbers, and special characters"
        )
        .max(256, "Query should not exceed 256 characters")
        .required("Query is required"),
      organization: Yup.string()
        .max(50, "Organization should not exceed 50 characters")
        .matches(/^[^\s].*$/, "Organization field should not contain spaces at the beginning"),
      }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await singupApi({ ...values });
        resetForm();
        
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const checkEmailOnChange = async (e) => {
    formik.handleChange(e);
    formik.setFieldTouched('email', true, true); // Mark email field as touched
    setIsCheckingEmail(true);
    const { error, data } = await checkEmailApi({ email: e.target.value });
    setIsCheckingEmail(false);
    if (error) {
      formik.setErrors({ email: "Email already exists" });
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  return (
    <section
      className="ftco-counter img ftco-section ftco-no-pt ftco-no-pb my-5"
      id="free-trial"
    >
      <div className="container">
        <div className="row align-items-center pb-3">
          <div className="col-md-6 col-lg-7">
            <div className="py-md-5">
              <div className="row justify-content-start pb-3">
                <div className="col-md-12 heading-section ">
                  <h2
                    className="mb-4 mt-5"
                    style={{ fontSize: 34, textTransform: "capitalize" }}
                  >
                    Sign Up For a Free Trial
                  </h2>
                  <p>
                    We are so confident that you'll love how Eventease360 works
                    with you, we'll give you 7 days to test it out at no cost or
                    commitment.
                  </p>
                  <form onSubmit={formik.handleSubmit} className="contact-form">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <small className="text-danger">
                          {formik.errors.name}
                        </small>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        id="organization"
                        name="organization"
                        className="form-control"
                        placeholder="Organization, Market or Event Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.organization}
                      />
                      {formik.touched.organization &&
                        formik.errors.organization && (
                          <small className="text-danger">
                            {formik.errors.organization}
                          </small>
                        )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        maxLength={10}
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <small className="text-danger">
                          {formik.errors.phone}
                        </small>
                      )}
                    </div>
                    
                    <div className="form-group">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Your Email"
                        onChange={checkEmailOnChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <small className="text-danger">
                          {formik.errors.email}
                        </small>
                      )}
                    </div>
                    <div className="form-group">
                      <textarea
                        id="about"
                        name="about"
                        className="form-control"
                        placeholder="Tell us about your market or event"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.about}
                        maxLength={256}
                      />
                      <p className="text-end mb-0">
                        <small className="text-end">
                          {formik.values.about.length}/256
                        </small>
                      </p>

                      {formik.touched.about && formik.errors.about && (
                        <small className="text-danger">
                          {formik.errors.about}
                        </small>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="submit"
                        value="Sign up"
                        className="btn btn-primary py-3 px-5"
                        disabled={!isEmailValid || isCheckingEmail}
                      />
                      {isCheckingEmail && (
                        <small className="text-info">Checking email...</small>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <img src="assets/images/about.jpg" className="img-fluid" alt="About" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubAdminSignUp;
